import { UIEvent, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EmptyState } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import { selectFilteredComments } from 'Editor/redux/CommentsSlice';
import {
  selectFilteredCommentsActive,
  selectIsPageLayout,
  selectReadOnlyMode,
  selectUser,
} from 'Editor/redux/EditorStatusSlice';
import { selectHasFilters } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState } from '_common/components';
import CommentCard from 'Editor/components/CommentCard';

import styles from './CommentList.module.scss';
import interactionControllerStyles from '_common/components/OnboardingOverlay/InteractionController.module.scss';

const SLICE_CHUNK = 20;

const CommentList = () => {
  const intl = useIntl();

  const containerRef = useRef(null);
  const scrollFlag = useRef(false);

  const user = useSelector(selectUser);
  const isReadOnlyMode = useSelector(selectReadOnlyMode);
  const isPageLayout = useSelector(selectIsPageLayout);
  const comments = useSelector(selectFilteredComments);
  const commentsActive = useSelector(selectFilteredCommentsActive);
  const hideAllComments = useSelector((state) => state.editor.comments.hideAll);

  const hasActiveFilters = useSelector((state) =>
    selectHasFilters(state, state.filters.editorCommentPanel),
  );

  const [sliceIndex, setSliceIndex] = useState(0);

  useEffect(() => {
    if (scrollFlag.current) {
      const id = `Comment#${commentsActive[0]}`;
      const element = document.querySelector(`*[id="${id}"]`);
      element?.scrollIntoView({ block: 'center' });
      scrollFlag.current = false;
    }
  }, [sliceIndex]);

  useEffect(() => {
    if (commentsActive.length > 0) {
      const index = comments.order.indexOf(commentsActive[0]);
      const indexOfTemporaryComment = index === -1;

      if ((index >= sliceIndex && index < sliceIndex + SLICE_CHUNK) || indexOfTemporaryComment) {
        const id = `Comment#${commentsActive[0]}`;

        const element = document.querySelector(`*[id="${id}"]`);
        if (element) {
          element.scrollIntoView({ block: 'center' });
        }
      } else {
        scrollFlag.current = true;
        setSliceIndex(Math.max(index - SLICE_CHUNK / 2, 0));
      }
    }
  }, [commentsActive, comments.insert]);

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.scrollHeight - target.scrollTop < target.clientHeight + 150) {
      setSliceIndex(Math.min(sliceIndex + SLICE_CHUNK / 2, comments.order.length - SLICE_CHUNK));
    } else if (target.scrollTop < 150) {
      setSliceIndex(Math.max(sliceIndex - SLICE_CHUNK / 2, 0));
    }
  };

  const renderEmptyState = () => (
    <div className={styles.emptyView}>
      <EmptyState
        size="medium"
        title={intl.formatMessage({ id: 'NO_COMMENTS_FOUND' })}
        testId="no-comments-found"
      >
        <FormattedMessage id="NO_COMMENTS_FOUND_MESSAGE" />
      </EmptyState>
    </div>
  );

  if (comments.order.length === 0 && !comments.insert.inserting) {
    if (hasActiveFilters) {
      return <EmptyFilteredState identity="editorCommentPanel" size="medium" />;
    }
    return renderEmptyState();
  } else if (hideAllComments) {
    return renderEmptyState();
  }

  return (
    <>
      <div
        id={interactionControllerStyles.skipControl}
        className={styles.root}
        onScroll={onScroll}
        ref={containerRef}
        data-testid="comments-list"
      >
        <>
          {comments.insert.inserting && (
            <div style={{ marginBottom: '1rem' }}>
              <CommentCard isTemporary sidebar user={user.id} />
            </div>
          )}
          {!hideAllComments &&
            comments.order
              .slice(
                Math.max(sliceIndex - SLICE_CHUNK, 0),
                Math.min(sliceIndex + SLICE_CHUNK, comments.order.length),
              )
              .filter((id) => comments.comments[id])
              .map((id) => (
                <div key={id} className={styles.comment}>
                  <CommentCard
                    sidebar
                    user={user.id}
                    selected={commentsActive.includes(id)}
                    comment={comments.comments[id]}
                    isReadOnlyMode={isReadOnlyMode || isPageLayout}
                  />
                </div>
              ))}
        </>
      </div>
    </>
  );
};

export default CommentList;
