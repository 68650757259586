export const getSanitizedEntityName = (
  entity: NonNullable<
    Citation['author'] | Citation['editor'] | Citation['corporate_author']
  >[number],
) => {
  if (!entity.first_name && !entity.last_name) {
    return null;
  }

  if (entity.first_name && !entity.last_name) {
    return entity.first_name;
  }

  if (!entity.first_name && entity.last_name) {
    return entity.last_name;
  }

  return `${entity.first_name} ${entity.last_name}`;
};
